import { Attachment } from '../models/attachments.model';
import { IMatterListEntry } from '@app/features/+matter-list/models';
import { OutlookContact } from '@app/features/+matter-details/models';
import { isNumber } from '@server/modules/shared/functions/common-util.functions';
import { ExtraProperty } from '@leapdev/leap-host/dist/api';

export const createEmail = (options: Partial<ICreateEmailOptions>): IEmail => ({
  id: options.id,
  attachments: options.attachments || [],
  bcc: options.bcc || [],
  body: options.body,
  cc: options.cc || [],
  importance: isNumber(options.importance) ? options.importance : 1, // TODO get enum
  isDraft: options.isDraft,
  matterId: options?.matterId,
  matterNumber: options?.matterNumber,
  sender: options?.sender,
  subject: options.subject,
  to: options.to || [],
  folderId: options.folderId,
  xmlData: options.xmlData,
});

export const toEmail = (email: IEmailDTO): IEmail => ({
  id: email.id,
  attachments: email.attachments || [],
  bcc: email.bcc || [],
  body: email.body,
  cc: email.cc || [],
  importance: isNumber(email.importance) ? email.importance : 1, // TODO get enum
  isDraft: email.isDraft,
  matterId: email.matterId,
  matterNumber: email.matterNo,
  sender: email.sender,
  subject: email.subject,
  to: email.to || [],
  folderId: email.folderId,
  staffInitials: email.staffInitials,
});

const isEmptyString = (val: string) => (val || '').split(' ').filter((x) => x).length === 0;

export const toEmailDTO = (email: IEmail): IEmailDTO => ({
  id: email.id,
  attachments: email.attachments || [],
  bcc: email.bcc || [],
  body: email.body,
  cc: email.cc || [],
  importance: isNumber(email.importance) ? email.importance : 1, // TODO get enum
  isDraft: email.isDraft,
  matterId: email.matterId,
  matterNo: email.matterNumber,
  sender: email.sender,
  subject: isEmptyString(email.subject) ? '(Blank)' : email.subject,
  to: email.to || [],
  folderId: email.folderId,
  staffInitials: email.staffInitials,
  xmlData: email.xmlData
});

export enum IAttachmentType {
  File = 0,
  Correspondence = 1,
}

export interface Emails {
  to: IEmailAddress[];
  cc: IEmailAddress[];
  bcc: IEmailAddress[];
}
export interface IEmailAddress {
  address: string;
  name: string;
  isFromCard?: boolean;
}

export interface IOutlookUser {
  email: string;
  firstName: string;
  linked: boolean;
  lastName: string;
  userType: string;
  userId: string;
}

export interface User {
  email: string;
  firstName: string;
  lastName: string;
  name: string;
}

export interface IEmail {
  id: number;
  attachments: Attachment[];
  bcc: IEmailAddress[];
  body: string;
  cc: IEmailAddress[];
  importance: Importance;
  isDraft: boolean;
  matterId: string;
  matterNumber: string;
  sender: IEmailAddress;
  subject: string;
  to: IEmailAddress[];
  folderId: string;
  staffInitials?: string;
  xmlData?: string
}

export interface UpdateOutput extends IEmail {
  isValid: boolean;
  isDirty: boolean;
  loading: boolean;
}

export interface IEmailDTO {
  id: number;
  attachments: Attachment[];
  bcc: IEmailAddress[];
  body: string;
  cc: IEmailAddress[];
  importance: Importance;
  isDraft: boolean;
  matterId: string;
  matterNo: string;
  sender: IEmailAddress;
  subject: string;
  to: IEmailAddress[];
  folderId: string;
  staffInitials?: string;
  xmlData?: string
}

export enum Importance {
  Low = 0,
  Normal = 1,
  High = 2,
}

interface IEmailResponse {
  id: number;
  failedAttachments: string[];
}

export interface ICreateEmailOptions {
  id?: number;
  attachments?: Attachment[];
  bcc?: IEmailAddress[];
  body?: string;
  cc?: IEmailAddress[];
  importance?: Importance;
  isDraft?: boolean;
  matterId: string;
  matterNumber: string;
  sender: IEmailAddress;
  subject?: string;
  to?: IEmailAddress[];
  folderId?: string;
  xmlData?: string
}

export interface ILaunchEmailOptions {
  id?: number;
  attachments?: Attachment[];
  bcc?: IEmailAddress[];
  body?: string;
  cc?: IEmailAddress[];
  importance?: Importance;
  isDraft?: boolean;
  matterId: string;
  matterNumber?: string;
  subject?: string;
  to?: IEmailAddress[];
  folderId?: string;
  ext?: string;
  latestVersionId?: string;
  documentId: string;

  // pdfEmailOptions?: IPDFAndEmailOptions; -- to be imported from create-pdf.module
}

export type CreateEmailRequest = IEmailDTO;
export type CreateEmailResponse = IEmailResponse;

export type DeleteEmailResponse = void;

export interface GetEmailsParams {
  matterId: string;
  staffId: string;
}

export type UpdateEmailRequest = IEmailDTO;
export type UpdateEmailResponse = IEmailResponse;

export const toUpdateEmailRequest = (email: IEmail): CreateEmailRequest => toEmailDTO(email);

export interface MatterAndContactDetail {
  matter: IMatterListEntry;
  contact: OutlookContact[];
}

export const isValidEmail = (email) => {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
